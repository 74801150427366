<template>
  <div class="form-check">
    <input class="form-check-input" type="checkbox" :id="inputId" :value="value" :disabled="disabled" :checked="proxyChecked" @click="clicked">
    <label class="form-check-label" :for="inputId"><slot /></label>
  </div>
</template>

<script>

import formInputCheckboxMixin from './mixins/formInputCheckbox.js';

export default {
  mixins: [formInputCheckboxMixin],
};

</script>
