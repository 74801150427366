import formInputMixin from './formInput.js';
import formInputCheckboxPropsMixin from './formInputCheckboxProps.js';

export default {
  mixins: [formInputMixin, formInputCheckboxPropsMixin],
  data () {
    return {
      proxyChecked: this.getProxyCheckedValue(),
    };
  },
  watch: {
    checked (newVal) {
      this.proxyChecked = this.getProxyCheckedValue(newVal);
    },
  },
  methods: {
    getProxyCheckedValue (value = this.value) {
      if (typeof value === typeof true) {
        return value;
      }

      return (value !== null) ? this.checked.includes(value) : !!this.checked;
    },
    clicked (event) {
      if (this.confirmRequired) {
        if (!confirm(this.confirmText)) {
          event.preventDefault();
          event.stopPropagation();

          return;
        }
      }

      this.emitCheck();
    },
    emitCheck () {
      if (this.value === null || typeof this.value === typeof true) {
        this.proxyChecked = !this.proxyChecked;
        this.$emit('input', this.proxyChecked);

        return;
      }

      const newValue = this.checked.filter(c => c !== this.value);
      if (!this.proxyChecked) {
        this.proxyChecked = true;
        newValue.push(this.value);
      }

      this.$emit('input', newValue);
    },
  },
};
